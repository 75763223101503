<template>
  <div class="wrapper">
    <div class="main-content">
      <section>
        <div class="container">
          <div class="row">
            <h1>{{ patterns.title }}</h1>
            <p>{{ patterns.description }}</p>
            <h2>Pattern List</h2>
            <div class="d-flex justify-content-center align-items-center">
              <div class="row">
                <div
                  v-for="(pattern, index) in patterns.patterns"
                  :key="index"
                  class="col-md-12 mb-4 pattern-card"
                >
                  <div class="card zoom-card" @mouseenter="zoomIn" @mouseleave="zoomOut">
                    <div class="card-body">
                      <h3 class="card-title">{{ pattern.name }}</h3>
                      <p class="card-text">{{ pattern.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'Patterns',
    data() {
      return {
        patterns: {
          title: "Candlestick Patterns for AI Analysis",
          description:
            "Discover a wide range of candlestick patterns utilized in our AI-driven analysis. These candlestick patterns are essential for identifying potential price movements and trend reversals in financial markets. Dive into the world of candlestick patterns and understand how our AI incorporates them to make more accurate predictions.",
          patterns: [
            {
              name: "Bullish Engulfing Pattern",
              description:
                'The bullish engulfing pattern is a two-candlestick formation that signals a potential reversal of a downtrend. It occurs when a small bearish candle is followed by a larger bullish candle that completely engulfs the previous candle.'
            },
            {
              name: 'Bearish Engulfing Pattern',
              description:
                'The bearish engulfing pattern is a two-candlestick formation that signals a potential reversal of an uptrend. It occurs when a small bullish candle is followed by a larger bearish candle that completely engulfs the previous candle.'
            },
            {
              name: 'Abandoned Baby',
              description:
                'The abandoned baby pattern is a reversal pattern characterized by a gap, followed by a doji, and then another gap in the opposite direction. It suggests a potential reversal in the current trend.'
            },
            {
            "key": "doji",
            "name": "Doji",
            "description": "A doji is a candlestick pattern with a small body, indicating that the opening and closing prices are very close. It represents market indecision and is often seen as a potential reversal signal."
        },
        {
            "key": "bearishengulfingpattern",
            "name": "Bearish Engulfing Pattern",
            "description": "The bearish engulfing pattern is a bearish reversal pattern where a small bullish candle is followed by a larger bearish candle that engulfs the previous one."
        },
        {
            "key": "bullishengulfingpattern",
            "name": "Bullish Engulfing Pattern",
            "description": "The bullish engulfing pattern is a bullish reversal pattern where a small bearish candle is followed by a larger bullish candle that engulfs the previous one."
        },
        {
            "key": "darkcloudcover",
            "name": "Dark Cloud Cover",
            "description": "The dark cloud cover is a bearish reversal pattern characterized by a bearish candle following a bullish one, with the bearish candle closing below the midpoint of the previous bullish candle."
        },
        {
            "key": "downsidetasukigap",
            "name": "Downside Tasuki Gap",
            "description": "The downside tasuki gap is a bearish continuation pattern that appears in a downtrend. It consists of two bearish candles with a gap between them, indicating a potential continuation of the downtrend."
        },
        {
            "key": "dragonflydoji",
            "name": "Dragonfly Doji",
            "description": "The dragonfly doji is a bullish reversal pattern characterized by a small body with a long lower shadow and no upper shadow. It suggests a potential reversal from a downtrend to an uptrend."
        },
        {
            "key": "gravestonedoji",
            "name": "Gravestone Doji",
            "description": "The gravestone doji is a bearish reversal pattern characterized by a small body with a long upper shadow and no lower shadow. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "bullishharami",
            "name": "Bullish Harami",
            "description": "The bullish harami is a bullish reversal pattern where a small bearish candle is followed by a larger bullish candle. It suggests a potential reversal from a downtrend to an uptrend."
        },
        {
            "key": "bearishharami",
            "name": "Bearish Harami",
            "description": "The bearish harami is a bearish reversal pattern where a small bullish candle is followed by a larger bearish candle. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "bullishharamicross",
            "name": "Bullish Harami Cross",
            "description": "The bullish harami cross is a bullish reversal pattern where a doji or small candle is followed by a larger bullish candle. It suggests a potential reversal from a downtrend to an uptrend."
        },
        {
            "key": "bearishharamicross",
            "name": "Bearish Harami Cross",
            "description": "The bearish harami cross is a bearish reversal pattern where a doji or small candle is followed by a larger bearish candle. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "eveningdojistar",
            "name": "Evening Doji Star",
            "description": "The evening doji star is a bearish reversal pattern that consists of a large bullish candle, followed by a doji, and then a large bearish candle. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "eveningstar",
            "name": "Evening Star",
            "description": "The evening star is a bearish reversal pattern that consists of a large bullish candle, followed by a small-bodied candle, and then a large bearish candle. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "morningdojistar",
            "name": "Morning Doji Star",
            "description": "The morning doji star is a bullish reversal pattern that consists of a large bearish candle, followed by a doji, and then a large bullish candle. It suggests a potential reversal from a downtrend to an uptrend."
        },
        {
            "key": "morningstar",
            "name": "Morning Star",
            "description": "The morning star is a bullish reversal pattern that consists of a large bearish candle, followed by a small-bodied candle, and then a large bullish candle. It suggests a potential reversal from a downtrend to an uptrend."
        },
        {
            "key": "bullishmarubozu",
            "name": "Bullish Marubozu",
            "description": "The bullish marubozu is a bullish continuation pattern characterized by a long green candle with no or very small shadows. It suggests a strong bullish trend."
        },
        {
            "key": "bearishmarubozu",
            "name": "Bearish Marubozu",
            "description": "The bearish marubozu is a bearish continuation pattern characterized by a long red candle with no or very small shadows. It suggests a strong bearish trend."
        },
        {
            "key": "piercingline",
            "name": "Piercing Line",
            "description": "The piercing line is a bullish reversal pattern where a small bullish candle follows a large bearish candle and closes above the midpoint of the bearish candle's body."
        },
        {
            "key": "bullishspinningtop",
            "name": "Bullish Spinning Top",
            "description": "The bullish spinning top is a candlestick pattern characterized by a small body with upper and lower shadows. It suggests market indecision but may signal a potential bullish reversal."
        },
        {
            "key": "bearishspinningtop",
            "name": "Bearish Spinning Top",
            "description": "The bearish spinning top is a candlestick pattern characterized by a small body with upper and lower shadows. It suggests market indecision but may signal a potential bearish reversal."
        },
        {
            "key": "threeblackcrows",
            "name": "Three Black Crows",
            "description": "The three black crows is a bearish reversal pattern that consists of three consecutive long bearish candles. It suggests a strong bearish sentiment and potential further price decline."
        },
        {
            "key": "threewhitesoldiers",
            "name": "Three White Soldiers",
            "description": "The three white soldiers is a bullish reversal pattern that consists of three consecutive long bullish candles. It suggests a strong bullish sentiment and potential further price rise."
        },
        {
            "key": "bullishhammerstick",
            "name": "Bullish Hammer Stick",
            "description": "The bullish hammer stick is a bullish reversal pattern characterized by a small body with a long lower shadow. It suggests a potential reversal from a downtrend to an uptrend."
        },
        {
            "key": "bearishhammerstick",
            "name": "Bearish Hammer Stick",
            "description": "The bearish hammer stick is a bearish reversal pattern characterized by a small body with a long upper shadow. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "bullishinvertedhammerstick",
            "name": "Bullish Inverted Hammer Stick",
            "description": "The bullish inverted hammer stick is a bullish reversal pattern characterized by a small body with a long upper shadow. It suggests a potential reversal from a downtrend to an uptrend."
        },
        {
            "key": "bearishinvertedhammerstick",
            "name": "Bearish Inverted Hammer Stick",
            "description": "The bearish inverted hammer stick is a bearish reversal pattern characterized by a small body with a long lower shadow. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "hammerpattern",
            "name": "Hammer Pattern",
            "description": "The hammer pattern is a bullish reversal pattern characterized by a small body with a long lower shadow. It suggests a potential reversal from a downtrend to an uptrend."
        },
        {
            "key": "hammerpatternunconfirmed",
            "name": "Hammer Pattern (Unconfirmed)",
            "description": "The unconfirmed hammer pattern is a potential bullish reversal pattern characterized by a small body with a long lower shadow. It suggests a possible reversal from a downtrend to an uptrend but requires confirmation."
        },
        {
            "key": "hangingman",
            "name": "Hanging Man",
            "description": "The hanging man is a bearish reversal pattern characterized by a small body with a long lower shadow. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "hangingmanunconfirmed",
            "name": "Hanging Man (Unconfirmed)",
            "description": "The unconfirmed hanging man is a potential bearish reversal pattern characterized by a small body with a long lower shadow. It suggests a possible reversal from an uptrend to a downtrend but requires confirmation."
        },
        {
            "key": "shootingstar",
            "name": "Shooting Star",
            "description": "The shooting star is a bearish reversal pattern characterized by a small body with a long upper shadow. It suggests a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "shootingstarunconfirmed",
            "name": "Shooting Star (Unconfirmed)",
            "description": "The unconfirmed shooting star is a potential bearish reversal pattern characterized by a small body with a long upper shadow. It suggests a possible reversal from an uptrend to a downtrend but requires confirmation."
        },
        {
            "key": "tweezertop",
            "name": "Tweezer Top",
            "description": "The tweezer top is a bearish reversal pattern that occurs when two consecutive candles have the same high price, suggesting a potential reversal from an uptrend to a downtrend."
        },
        {
            "key": "tweezerbottom",
            "name": "Tweezer Bottom",
            "description": "The tweezer bottom is a bullish reversal pattern that occurs when two consecutive candles have the same low price, suggesting a potential reversal from a downtrend to an uptrend."
        }
          ],
        },
      };
    },
    metaInfo() {
    return {
      title: this.patterns.title,
      meta: [
        {
          name: 'description',
          content: this.patterns.description,
        },
      ],
    };
  },
    methods: {
      zoomIn(event) {
        event.currentTarget.classList.add('zoomed');
      },
      zoomOut(event) {
        event.currentTarget.classList.remove('zoomed');
      },
    },
  };
  </script>
  
  <style scoped>
  .pattern-card {
    background-color: #ffc700;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin: 1em;
    padding: 1em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    cursor: pointer;
    width: calc(100% - 2em);
    margin: 1em auto;
  }
  
  .pattern-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .pattern-card h3 {
    font-size: 1.6em;
    color: #343a40;
    margin-bottom: 0.5em;
  }
  
  .pattern-card p {
    font-size: 1.2em;
    color: #6c757d;
    margin-bottom: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /*
  @media screen and (max-width: 48em) {
    .pattern-card {
      width: calc(100% - 2em);
    }
    h1 {
      font-size: 2em; 
    }
    .h2 {
      font-size: medium;
    }
    .pattern-card h3 {
      font-size: 1.2em; 
    }
  
    .pattern-card p {
      font-size: 1em; 
    }

  }
  
  @media screen and (min-width: 48em) and (max-width: 62em) {
    .pattern-card {
      width: calc(48% - 2em);
    }
  }
  
  @media screen and (min-width: 62em) {
    .pattern-card {
      width: calc(30% - 2em);
    }
  }*/

  </style>





  